.root{
    font-family: Inter, Roboto, sans-serif;
    padding-top: 16px;
}

.simple_root{
    padding-top: 0;
}

.image_container{
    display: grid;
    height: 160px;
    width: 100%;
}

.image_container .img{
    width: 100%;
    height: 100%;
}
.img img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.grid_zero{
    display: none;
}

.grid_one{
    grid-template-rows: 100%;
    grid-template-columns: 100%;
}

.grid_two{
    grid-template-rows: 100%;
    grid-template-columns: calc(100% / 2 - 2px) 4px calc(100% / 2 - 2px);
}

.grid_two .img:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
}
.grid_two .img:nth-child(2) {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
}

.grid_tree_or_more{
    grid-template-rows: calc(100% / 2 - 2px) 4px calc(100% / 2 - 2px);
    grid-template-columns: calc(100% / 2 - 2px) 4px calc(100% / 2 - 2px);
}

.grid_tree_or_more .img:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 4;
}
.grid_tree_or_more .img:nth-child(2) {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
}
.grid_tree_or_more .img:nth-child(3){
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 4;
}

.more_image{
    grid-column: 3 / 4;
    grid-row: 3 / 4;
    background-color: rgba(0,0,0, .4);
    color: #ffffff;
    z-index: 10;
    font-size: 24px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

.primaryColor{
    color: #222222;
}

.root h3{
    margin-top: 8px;
}

.secondaryColor{
    color: #858585!important;
}